import React, { useReducer, createContext } from 'react';
import storage from './storage';

export const AppContext = createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_THEME':
      return { ...state, theme: action.theme };
    case 'SET_STRIPE_STATUS':
      return { ...state, isStripeSetup: action.status };
    case 'SET_ACCOUNT_ID':
      return { ...state, accountId: action.accountId };
    case 'SET_ACCOUNT_NAME':
      return { ...state, accountName: action.accountId };
    case 'SET_CAPABILITIIES':
      return { ...state, capabilities: action.capabilities };
    case 'SET_PROMOTERS':
      return { ...state, promoters: action.promoters };
    case 'SET_ME':
      return { ...state, user: action.user };
    case 'SET_ACCOUNT_SCANNERS':
      return { ...state, janamScanners: action.janamScanners };
    case 'SET_ACCOUNT_DATA':
      return { ...state, account: action.account };
    case 'SET_NAV_EXPANDED':
      return { ...state, navIsExpanded: action.navIsExpanded };
    default:
      return state;
  }
};

const setDefaultTheme = () => {
  const step = storage.get('current-step');

  if (!step || 'StepOne' === step || 'StepTwo' === step) {
    return 'light';
  } else {
    return 'dark';
  }
};

export default ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    theme: setDefaultTheme(),
  });

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
