import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import Icon from 'components/Icon';
import { useGetSpectatorReport } from 'components/Participants/gql/useGetSpectatorReport.mutation';
import { PrintParticipants } from 'components/Participants/PrintSpectators';
import { Table } from 'components/Table';
import Text from 'components/Text';
import { useSearchInput, useUpdateQueryString } from 'hooks/useSearchInput';
import Export from 'pages/app/Tickets/export';
import { EventHeader } from '../../EventsMenu/components/EventHeader';
import { Column } from '../../Header/StyledHeader';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: start;
  max-width: 80vw;
`;

const SpectatorsListReport = () => {
  const { input } = useSearchInput();
  const { queryString } = input;

  const [search, setSearch] = useState(queryString || '');
  const updateQueryString = useUpdateQueryString();

  const [spectatorReport, setSpectatorReport] = useState();

  //   const [count, setCount] = useState();
  const { id } = useParams();

  const location = useLocation();
  const { event } = location.state || {};

  //   const history = useHistory();
  //   const theme = useTheme();

  //   const { pathname } = useLocation();
  //   const userTypeRoute = pathname?.split('/').includes('admin-track')
  //     ? 'track'
  //     : pathname?.split('/').includes('admin-employee')
  //     ? 'employee'
  //     : 'admin';

  const getSpectatorReport = useGetSpectatorReport(event?.start_date ?? null);

  useEffect(() => {
    const getData = async () => {
      const response = await getSpectatorReport(id);

      setSpectatorReport(response.data?.getSpectatorReport);
    };

    getData();
  }, [getSpectatorReport, id, queryString]);

  //   const urlParams = new URLSearchParams(window.location.search);
  //   const currentPage = parseInt(urlParams.get('page'));

  const columns = [
    {
      label: "Purchaser's Name",
      key: 'purchaser',
    },
    {
      label: 'Tickets',
      key: 'tickets',
    },
    {
      label: 'Ticket Number',
      key: 'ticket_number',
    },
    {
      label: "Ticket Holder's Name",
      key: 'ticket_holder',
    },
    {
      label: 'Ticket Price',
      key: 'ticket_price',
    },
  ];

  function renderRows(tick) {
    const ticket = tick.tickets;
    const areAllTicketsRefunded = ticket.reduce((allRefunded, ticket) => {
      return !ticket.refunded ? false : allRefunded;
    }, true);
    const nonRefundedTickets = ticket.filter((ticket) => !ticket.refunded);

    if (areAllTicketsRefunded) return null;

    return {
      purchaser: tick.purchaser,
      tickets: nonRefundedTickets.reduce(
        (acc, ticket) =>
          acc.length
            ? acc.concat(`\r\n${ticket.ticket.name.toUpperCase()}`)
            : `${ticket.ticket.name.toUpperCase()}`,
        ''
      ),
      ticket_number: nonRefundedTickets.reduce(
        (acc, ticket) =>
          acc.length
            ? acc.concat(`\r\n${ticket.barcode}`)
            : `${ticket.barcode}`,
        ''
      ),
      ticket_holder: nonRefundedTickets.reduce(
        (acc, ticket) =>
          acc.length
            ? acc.concat(`\r\n${ticket.holder.toUpperCase()}`)
            : `${ticket.holder.toUpperCase()}`,
        ''
      ),
      ticket_price: nonRefundedTickets.reduce(
        (acc, ticket) =>
          acc.length ? acc.concat(`\r\n${ticket.price}`) : `${ticket.price}`,
        ''
      ),
    };
  }

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  return !spectatorReport ? (
    <div />
  ) : (
    <>
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          paddingTop: 20,
          paddingRight: 40,
        }}
      >
        <EventHeader event={event} margin={'40px 40px 0px 40px'} />

        <Column noBorder>
          <PrintParticipants
            eventId={id}
            text="Export PDF"
            date={event?.start_date}
            disable={!spectatorReport.data.length}
          />

          <Export
            text="Export CSV"
            event={event}
            date={event?.start_date}
            icon={<Icon icon="Export-Icon" size={40} />}
            disabled={!spectatorReport.data.length}
          />
        </Column>
      </div>

      <Container>
        <ContainerHeader
          borderWidth={'1px'}
          style={{ position: 'relative', width: '97%' }}
        >
          <TitleContainer
            style={{
              flexDirection: 'row',
              width: '95%',
              justifyContent: 'space-between',
            }}
          >
            <FilterContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, whiteSpace: 'nowrap' }}
              >
                Ticket Holder Report
              </Text>
              <SearchInput
                placeholder="Search Spectators"
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyPress}
                value={search}
              />
            </FilterContainer>
            <FilterContainer>
              <Text
                type="heading"
                as="h3"
                color="#3C4144"
                inlineStyle={{ marginRight: 20, whiteSpace: 'nowrap' }}
              >
                Tickets Sold: {spectatorReport.total_tickets}
              </Text>
            </FilterContainer>
          </TitleContainer>
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table
            items={spectatorReport.data}
            columns={columns}
            renderRows={renderRows}
          />
          {/* <Pagination
            count={count}
            perPage={15}
            currentPage={currentPage || 1}
          /> */}
        </div>
      </Container>
    </>
  );
};

export default SpectatorsListReport;
