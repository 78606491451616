import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
// import Icon from 'components/Icon';
import { useGetTicketBreakdownReport } from 'components/Participants/gql/useGetTicketBreakdownReport.mutation';
import { Table } from 'components/Table';
import Text from 'components/Text';
import { TicketReportPrint } from 'pages/app/Transactions/TicketReportPrint/TicketReportPrint';
// import Export from 'pages/app/Tickets/export';
import { EventHeader } from '../../EventsMenu/components/EventHeader';
import { Column } from '../../Header/StyledHeader';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: start;
  max-width: 80vw;
`;

const SpectatorsListReport = () => {
  const [spectatorReport, setSpectatorReport] = useState();
  const { id } = useParams();

  const location = useLocation();
  const { event } = location.state || {};

  const getSpectatorReport = useGetTicketBreakdownReport();

  useEffect(() => {
    const getData = async () => {
      const response = await getSpectatorReport(id);
      setSpectatorReport(response.data?.getTicketSalesReport);
    };

    getData();
  }, [getSpectatorReport, id]);

  const columns = [
    {
      label: 'Ticket Name',
      key: 'name',
    },
    {
      label: 'Price',
      key: 'price',
    },
    {
      label: 'Quantity',
      key: 'qty',
    },
  ];

  function renderRows(tick) {
    return tick;
  }

  return !spectatorReport ? (
    <div />
  ) : (
    <>
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          paddingTop: 20,
          paddingRight: 40,
        }}
      >
        <EventHeader event={event} margin={'40px 40px 0px 40px'} />
        <Column noBorder>
          <TicketReportPrint
            eventId={id}
            text="Export PDF"
            date={event?.start_date}
            disable={!spectatorReport.results.length}
          />

          {/* <Export
            text="Export CSV"
            event={event}
            date={event?.start_date}
            icon={<Icon icon="Export-Icon" size={40} />}
            disabled={!spectatorReport.results.length}
          /> */}
        </Column>
      </div>
      <Container>
        <ContainerHeader
          borderWidth={'1px'}
          style={{ position: 'relative', width: '97%' }}
        >
          <TitleContainer
            style={{
              flexDirection: 'row',
              width: '95%',
              justifyContent: 'space-between',
            }}
          >
            <FilterContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, whiteSpace: 'nowrap' }}
              >
                Ticket Breakdown Report
              </Text>
            </FilterContainer>
            <FilterContainer>
              <Text
                type="heading"
                as="h3"
                color="#3C4144"
                inlineStyle={{ marginRight: 20, whiteSpace: 'nowrap' }}
              >
                Total Sales: {spectatorReport.total}
              </Text>
              <Text
                type="heading"
                as="h3"
                color="#3C4144"
                inlineStyle={{ marginRight: 20, whiteSpace: 'nowrap' }}
              >
                Discounts: {spectatorReport.discounts}
              </Text>
              <Text
                type="heading"
                as="h3"
                color="#3C4144"
                inlineStyle={{ marginRight: 20, whiteSpace: 'nowrap' }}
              >
                Credits: {spectatorReport.credits}
              </Text>
            </FilterContainer>
          </TitleContainer>
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table
            items={spectatorReport.results}
            columns={columns}
            renderRows={renderRows}
          />
          {/* <Pagination
            count={count}
            perPage={15}
            currentPage={currentPage || 1}
          /> */}
        </div>
      </Container>
    </>
  );
};

export default SpectatorsListReport;
