import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'components/Button';
import EventManager from 'pages/track/EventManager';
import { useMoveReservedSeats } from '../../gql/mutation/useMoveReservedSeats';
import { ConfirmMoveSeatsModal } from './ConfirmMoveSeatsModal';
import { ReservedOrderDetail } from './ReservedOrderDetail';
import { ReservedOrders } from './ReservedOrders';

const steps = {
  1: 'viewReservedSeats',
  2: 'viewOrderDetails',
  3: 'viewSeatsMap',
};

export const MoveReservedSeats = ({ seatId }) => {
  const [step, setStep] = useState(steps[1]);
  const [selectedOrder, setSelectedOrder] = useState();
  const [selectedSeats, setSelectedSeats] = useState([]);

  const [isContinueDisabled, setIsContinueDisabled] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [newSelectedSeats, setNewSelectedSeats] = useState([]);

  const moveReservedSeats = useMoveReservedSeats();

  const onOpenReservedOrder = (id) => {
    setSelectedOrder(id);
    setStep(steps[2]);
  };

  const onMoveSeats = () => {
    setStep(steps[3]);
  };

  const onSeatSelected = (object) => {
    setNewSelectedSeats((prevSeats) => {
      // Check if the selected seats length is not greater than the number of seats selected
      if (prevSeats?.length >= selectedSeats?.length) {
        // Display an error message
        toast.error(
          'You cannot select more seats than the number of purchased seats selected'
        );

        setIsContinueDisabled(true);

        return prevSeats;
      }

      return [...prevSeats, object];
    });
  };

  const onSeatDeselected = (object) => {
    setNewSelectedSeats((prevSeats) => {
      // Check if the selected seats length is not greater than the number of seats selected
      if (prevSeats?.length <= selectedSeats?.length) {
        setIsContinueDisabled(false);
      }

      return prevSeats && prevSeats.filter((seat) => seat.uuid !== object.uuid);
    });
  };

  const onConfirmMoveSeats = () => {
    if (newSelectedSeats?.length > selectedSeats?.length) {
      // Display an error message
      toast.error(
        `Selected seats cannot be more than the number of purchased seats, please remove ${
          newSelectedSeats.length - selectedSeats.length
        } selected seats`
      );
      return;
    } else setIsVisible(true);
  };

  const onMoveSeatsComplete = async () => {
    const data = {
      purchase_id: selectedOrder,
      new_seat_ids: newSelectedSeats.map((seat) => seat.seatId),
      old_pass_ids: selectedSeats.map((seat) => seat.id),
    };

    try {
      const response = await moveReservedSeats(data);

      if (response && !response.errors) {
        toast.success('Seats moved successfully');
        setStep(steps[1]);
        setSelectedSeats([]);
        setNewSelectedSeats([]);
      } else {
        toast.error('An error occurred while moving seats');
      }
    } catch (error) {
      toast.error('An error occurred while moving seats');
    }
  };

  return (
    <div>
      {step === steps[1] && (
        <ReservedOrders onOpenDetail={onOpenReservedOrder} />
      )}
      {step === steps[2] && selectedOrder && (
        <ReservedOrderDetail
          purchaseId={selectedOrder}
          selectedSeats={selectedSeats}
          setSelectedSeats={setSelectedSeats}
          onMoveSeats={onMoveSeats}
        />
      )}
      {step === steps[3] && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              padding: '5px 5px 5px 0',
            }}
          >
            <Button onClick={onConfirmMoveSeats} disabled={isContinueDisabled}>
              Continue
            </Button>
          </div>
          <div style={{ height: '80vh', width: '80vw' }}>
            <EventManager
              seatId={seatId}
              mode="select"
              // manageForSaleConfig, manageObjectStatuses, manageTableBooking, manageChannels,
              onObjectSelected={onSeatSelected}
              onObjectDeselected={onSeatDeselected}
            />
          </div>

          <ConfirmMoveSeatsModal
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            selectedSeats={selectedSeats}
            newSelectedSeats={newSelectedSeats}
            onMoveSeatsComplete={onMoveSeatsComplete}
          />
        </div>
      )}
    </div>
  );
};
