import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const MOVE_RESERVED_SEATS = gql`
  mutation moveReservedSeats($input: MoveSeatsInput!) {
    moveReservedSeats(input: $input)
  }
`;

export function useMoveReservedSeats() {
  const [moveReservedSeatsMutation] = useMutation(MOVE_RESERVED_SEATS);

  const result = useCallback(
    (data) => {
      return moveReservedSeatsMutation({
        variables: { input: { ...data } },
        skip: !data,
      });
    },
    [moveReservedSeatsMutation]
  );
  return result;
}
