import moment from 'moment';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { EventHeaderMobile } from 'components/Events';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { SearchableListContainer } from 'components/SearchableListContainer';
import Spacer from 'components/Spacer';
import EventManager from 'pages/track/EventManager';
import { Tab, TabContent, Tabs } from '../../AddEvents/styles';
import { EventHeader } from './EventHeader';
import { MoveReservedSeats } from './ReservedSeats/MoveReservedSeats';

const tabsConfig = [
  {
    id: 0,
    name: 'View Seats',
  },
  {
    id: 1,
    name: 'Block Seats',
  },
  {
    id: 2,
    name: 'Move Seats',
  },
];

export const Content = styled.div`
  ${(props) => (props.active ? '' : 'display:none;')}
`;

export const ReservedSeats = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const location = useLocation();
  const { event } = location.state || {};

  const [active, setActive] = useState(0);
  const [chosenTab, setChosenTab] = useState({
    label: tabsConfig[0].name,
    value: tabsConfig[0].id,
  });

  const [seatId, setSeatId] = useState({
    value: event?.seat_events[0]?.seat_id,
    label: moment(event?.seat_events[0]?.date).format('MMM D - YYYY'),
  });

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <EventHeaderMobile queryDate={event?.date} noDropdown />
          <div style={{ width: '90%', margin: '0 5vw' }}>
            <AutoSuggest
              value={chosenTab}
              onChange={(value) => {
                setChosenTab(value);
                setActive(value.value);
              }}
              onBlur={() => {
                return;
              }}
              closeMenuOnSelect
              options={tabsConfig.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
            />

            <Spacer size={10} />

            <DateDropdown
              seatId={seatId}
              setSeatId={setSeatId}
              seatEvents={event?.seat_events}
            />

            <Spacer size={10} />
          </div>

          <Body active={active} seatId={seatId?.value} mode="static" />
        </>
      ) : (
        <SearchableListContainer
          title=""
          header={
            event && (
              <div>
                <EventHeader event={event} />
                <TabContent style={{ display: 'flex' }}>
                  <Tabs style={{ justifyContent: 'start', width: '100%' }}>
                    {tabsConfig.map((tab) => (
                      <Tab
                        onClick={(e) => handleClick(e)}
                        active={active === tab.id}
                        id={tab.id}
                        key={tab.id}
                      >
                        {tab.name}
                      </Tab>
                    ))}
                  </Tabs>

                  <DateDropdown
                    seatId={seatId}
                    setSeatId={setSeatId}
                    seatEvents={event?.seat_events}
                    customStyle={{
                      minWidth: 200,
                      marginLeft: 'auto',
                      paddingRight: 10,
                      backgroundColor: 'white',
                    }}
                  />
                </TabContent>
              </div>
            )
          }
          noSearch
          noContainerHeader
          customStyle={{ height: '80vh' }}
          contentPadding={0}
        >
          <Body active={active} seatId={seatId?.value} />
        </SearchableListContainer>
      )}
    </>
  );
};

const Body = ({ active, seatId }) => {
  return (
    <>
      <Content active={active === 0} style={{ height: '80vh', width: '80vw' }}>
        <EventManager
          seatId={seatId}
          mode="static"
          customStyle={{ height: '80vh' }}
        />
      </Content>

      <Content active={active === 1} style={{ height: '80vh', width: '80vw' }}>
        <EventManager
          seatId={seatId}
          mode="manageForSaleConfig"
          customStyle={{ height: '80vh' }}
        />
      </Content>

      <Content active={active === 2}>
        <MoveReservedSeats seatId={seatId} />
      </Content>
    </>
  );
};

const DateDropdown = ({ seatId, setSeatId, seatEvents, customStyle }) => {
  return (
    <div style={{ ...customStyle }}>
      <AutoSuggest
        value={seatId}
        onChange={(value) => {
          setSeatId(value);
        }}
        onBlur={() => {
          return;
        }}
        closeMenuOnSelect
        options={seatEvents.map((item) => {
          return {
            value: item.seat_id,
            label: moment(item.date).format('MMM D - YYYY'),
          };
        })}
      />
    </div>
  );
};
