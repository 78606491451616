import moment from 'moment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { DateCard } from 'components/DateCard';

const HeaderContainer = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;
  justify-content: start;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-shrink: 0; /* Prevent shrinking or growing vertically */
  height: 80px; /* Fixed height */
`;

const DateCardWrapper = styled.div`
  display: flex;
  min-width: 180px;

  @media (max-width: 768px) {
    min-width: 100px;
  }
`;

const EventName = styled.span`
  font-family: Barlow Condensed;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  word-break: break-word;
  max-height: 93px;
  overflow: hidden;
`;

export const TicketHeader = ({ event }) => {
  let item = null;
  if (event?.start_date) {
    item = {
      date: moment(event?.start_date, 'MM-DD-YYYY').format('MMM D'),
      day: moment(event?.start_date, 'MM-DD-YYYY').format('ddd'),
      month: moment(event?.start_date, 'MM-DD-YYYY').format('MMM'),
      end_date: moment(event?.end_date, 'MM-DD-YYYY').format('MM-DD-YYYY'),
      isMultiDay: event?.isMultiDay ?? true,
      listDates:
        moment(event?.start_date, 'MM-DD-YYYY').format('DD') +
        ' - ' +
        (event?.end_date
          ? moment(event?.end_date, 'MM-DD-YYYY').format('DD')
          : ''),
    };
  }

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <HeaderContainer>
      <DateCardWrapper>
        {item !== null && (
          <DateCard
            item={item}
            margin={'margin: 0 10px 0 0'}
            horizontal={!isMobile ? true : false}
            isCartList={!isMobile ? true : false}
          />
        )}
      </DateCardWrapper>

      <div
        style={{
          margin: 10,
          display: 'flex',
          justifyContent: 'start',
          flexDirection: 'column',
        }}
      >
        <EventName>{event?.name}</EventName>
      </div>
      <img
        id="logo"
        src="/images/th-simple-logo.png"
        width="200"
        height="100"
        style={{ display: 'none' }}
        alt="print-logo"
      />
    </HeaderContainer>
  );
};
