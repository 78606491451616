import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import Images from 'images';
import { printReceiptFunction } from 'pages/app/Transactions/TransactionDetail/Components/PrintReceipt';
import { printTickets } from 'pages/app/Transactions/TransactionDetail/Components/PrintTickets';
import { AppContext } from 'shared/AppContext';
import { useCart } from 'shared/CartContext';
import { useCheckoutPos } from './gql/queries/useCheckoutPOS';
import MobileDeliveryModal from './MobileDeliveryModal';
import { PrintingModal } from './PrintingModal';

const HeaderWrapper = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
  margin-top: 20px;

  @media (max-width: 768px) {
    padding: 5px;
    margin-bottom: 5px;
  }
`;

const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-left: 25px;
  font-family: Barlow Condensed;
  color: #000033;

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

const CloseIcon = styled.div`
  transform: rotate(45deg);
  display: flex;
  justify-content: end;
  margin-right: 20px;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const ButtonWrapper = styled.div`
  padding-inline: 35px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 5px;

  @media (max-width: 768px) {
    padding-inline: 15px;
  }
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #eaeaea;
  margin-inline: 35px;

  @media (max-width: 768px) {
    margin-inline: 10px;
    padding: 5px 10px;
  }
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RowText = styled.span`
  font-family: Barlow Condensed;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: #000033;
`;

const NumberInput = styled.div`
  font-family: Barlow Condensed;
  font-size: 37px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: #000033;
  padding: 10px;
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 32px;
    padding: 5px;
  }
`;

const MoneyText = styled.span`
  font-family: Barlow Condensed;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  text-align: right;
  color: #000033;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 40px;
  }
`;

const AmountInput = styled.input`
  font-family: Barlow Condensed;
  font-size: 56px;
  font-weight: 700;
  line-height: 55px;
  text-align: right;
  color: #000033;
  &::placeholder {
    color: #fa4616;
  }
  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 40px;
  }
`;

const STATUS_CONNECTED = 'Connected';

const CashPayment = ({ isVisible, setIsVisible }) => {
  const theme = useTheme();
  const inputRef = useRef(null);

  const isTab = useMediaQuery({ query: '(max-width: 1190px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const {
    total,
    tickets,
    accountId,
    setPurchaseId,
    connectionStatus,
    printer,
    event,
    isPrinting,
    setIsPrinting,
    account: eventAccount,
  } = useCart();

  const {
    state: { account },
  } = useContext(AppContext);

  const availableAccount = account || eventAccount;

  const posSettings =
    event && event?.pos_settings
      ? event?.pos_settings
      : availableAccount && availableAccount?.pos_settings
      ? availableAccount?.pos_settings
      : undefined;

  const calculator = posSettings ? posSettings?.calculator : true;

  const print = posSettings ? posSettings?.ticket_delivery === 'print' : false;
  const printReceiptEnabled = posSettings
    ? posSettings?.receipt_delivery === 'print'
    : false;
  const printReceiptWithTickets = posSettings
    ? posSettings?.receipt_delivery === 'print_w_tickets'
    : false;

  const [amountEntered, setAmountEntered] = useState();

  const [loading, setLoading] = useState(false);

  const [openDeliveryModal, setOpenDeliveryModal] = useState(false);

  const onNumberClicked = (value) => {
    setAmountEntered((amountEntered) =>
      amountEntered ? `${amountEntered}${value}` : value
    );
  };

  const deleteLast = () => {
    setAmountEntered((amountEntered) => {
      if (amountEntered === 0) return 0.0;

      return amountEntered.length > 1
        ? amountEntered?.slice(0, -1) === ''
          ? ''
          : amountEntered.slice(0, -1)
        : '';
    });
  };

  const onChange = (e) => {
    // log the last string from the value from the input
    setAmountEntered(e.target.value);
  };

  useEffect(() => {
    if (isVisible && inputRef.current) inputRef.current.focus();
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && !calculator) handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, calculator]);

  const params = useParams();
  const eventId = parseInt(params.id);

  const checkoutPos = useCheckoutPos();

  const handleConfirm = async () => {
    setLoading(true);
    // read seatsio from session storage
    const seatsio = JSON.parse(sessionStorage.getItem('seatsio'));
    const holdToken = seatsio?.holdToken;

    const allTickets = Object.values(tickets).reduce((acc, value) => {
      const tickets = value.tickets?.map((ticket) => ({
        id: ticket.id,
        qty: ticket.count,
        ...(ticket.reserved_seat && ticket.count > 0
          ? {
              seats: ticket.seats?.map((seat) => ({
                seat_id: seat.id,
                ...(seat?.type ? { type: seat.type } : null),
              })),
            }
          : null),
      }));

      return [...acc, ...tickets];
    }, []);

    const result = await checkoutPos({
      event_id: eventId,
      account_id: accountId,
      tickets: allTickets,
      hold_token: holdToken,
      payment_method: 'cash',
    });

    if (result && result?.data) {
      // Reset MODAL values and hide the modal before printing
      setAmountEntered('');
      setLoading(false);
      setIsVisible(false);
      setPurchaseId(result.data.checkoutPOS);

      // Print the tickets
      if (connectionStatus === STATUS_CONNECTED) {
        if (print) {
          // Display Printing Modal
          setIsPrinting(true);

          await printTickets({
            printer,
            transaction: result?.data?.checkoutPOS,
            setIsPrinting,
          });

          // Print receipt with tickets only if the setting for printing tickets is enabled
          if (printReceiptWithTickets) {
            await printReceiptFunction({
              connectionStatus,
              printer,
              transaction: result?.data?.checkoutPOS,
              setIsPrinting,
            });
          }
        }
        if (printReceiptEnabled) {
          // Display Printing Modal
          setIsPrinting(true);

          await printReceiptFunction({
            connectionStatus,
            printer,
            transaction: result?.data?.checkoutPOS,
            setIsPrinting,
          });
        }
      }

      setOpenDeliveryModal(true);
    }
  };

  const changeDue = amountEntered
    ? (+amountEntered - total.toString().replace('$', '') ?? 0).toFixed(2)
    : `-${total.toString().replace('$', '') ?? 0}`;
  const modalStyle = {
    height: isMobile ? '85dvh' : '95dvh',
    maxHeight: '95dvh',
    padding: 0,
    right: isMobile ? 0 : 20,
    top: isTab ? '30px' : '2.5vh',
    margin: 'auto 0',
    width: isMobile ? '100vw' : isTab ? '60vw' : '40vw', // Conditional width
    maxWidth: 550, // Disable maxWidth on smaller screens
    minWidth: isMobile ? 350 : null, // Full width on small screens
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <Modal
      maxWidth={600}
      modalStyle={modalStyle}
      overlayStyle={{
        zIndex: 10,
      }}
      isVisible={isVisible}
      hideModal={() => setIsVisible(false)}
      noHeader={true}
    >
      <HeaderWrapper>
        <HeaderText>Cash Payment</HeaderText>

        <CloseIcon>
          <Icon
            icon="plus"
            color={theme.colors.primary}
            size={24}
            onClick={() => setIsVisible(false)}
            style={{ transform: 'rotate(45deg)', fontWeight: 'bold' }}
          />
        </CloseIcon>
      </HeaderWrapper>

      {loading || !calculator ? (
        <Loading />
      ) : (
        <BodyWrapper>
          <BalanceWrapper>
            <RowDiv>
              <RowText>Balance Due</RowText>
              <MoneyText>{total}</MoneyText>
            </RowDiv>

            <Spacer size={10} />

            <RowDiv>
              <RowText>Cash Tendered</RowText>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  minWidth: '40%',
                  maxHeight: 65,
                }}
              >
                <AmountInput
                  style={{
                    textAlign: 'end',
                    width: '100%',
                    color: '#FA4616',
                    maxWidth: 170,
                    backgroundColor: 'transparent',
                    borderRadius: 8,
                    border: '4px solid #FA4616',
                    minHeight: isMobile ? 45 : 65,
                    maxHeight: isMobile ? 45 : 65,
                  }}
                  id="amountEntered"
                  name="amountEntered"
                  onChange={onChange}
                  value={amountEntered}
                  ref={inputRef}
                  placeholder="0"
                />
              </div>
            </RowDiv>
          </BalanceWrapper>

          <BalanceWrapper
            style={{ backgroundColor: 'white', paddingInline: 35 }}
          >
            <RowDiv>
              <NumberInput onClick={() => onNumberClicked(1)}>1</NumberInput>
              <NumberInput onClick={() => onNumberClicked(2)}>2</NumberInput>
              <NumberInput onClick={() => onNumberClicked(3)}>3</NumberInput>
            </RowDiv>
            <RowDiv>
              <NumberInput onClick={() => onNumberClicked(4)}>4</NumberInput>
              <NumberInput onClick={() => onNumberClicked(5)}>5</NumberInput>
              <NumberInput onClick={() => onNumberClicked(6)}>6</NumberInput>
            </RowDiv>
            <RowDiv>
              <NumberInput onClick={() => onNumberClicked(7)}>7</NumberInput>
              <NumberInput onClick={() => onNumberClicked(8)}>8</NumberInput>
              <NumberInput onClick={() => onNumberClicked(9)}>9</NumberInput>
            </RowDiv>
            <RowDiv>
              <NumberInput onClick={() => onNumberClicked('.')}>.</NumberInput>
              <NumberInput onClick={() => onNumberClicked(0)}>0</NumberInput>
              <NumberInput onClick={deleteLast}>
                <img
                  src={Images.delete}
                  width={32}
                  height={26}
                  alt="Delete Icon"
                />
              </NumberInput>
            </RowDiv>
          </BalanceWrapper>

          <div>
            <BalanceWrapper>
              <RowDiv>
                <RowText>Change Due</RowText>
                <MoneyText style={{ color: '#FA4616' }}>${changeDue}</MoneyText>
              </RowDiv>
            </BalanceWrapper>
            <ButtonWrapper>
              <Button
                buttonStyle={{
                  borderRadius: 8,
                  minWidth: '40%',
                  marginInline: 'auto',
                  flex: 1,
                  padding: '10px 0',
                }}
                textColor={'#fa4616'}
                onClick={() => setIsVisible(false)}
                outlined
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '34px',
                  }}
                >
                  Cancel
                </span>
              </Button>

              <Spacer size={5} />

              <Button
                buttonStyle={{
                  borderRadius: 8,
                  minWidth: '40%',
                  marginInline: 'auto',
                  flex: 1,
                  padding: '13px 0',
                }}
                onClick={handleConfirm}
                disabled={+changeDue < 0}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '34px',
                  }}
                >
                  Confirm
                </span>
              </Button>
            </ButtonWrapper>
          </div>
        </BodyWrapper>
      )}

      <MobileDeliveryModal
        isVisible={openDeliveryModal}
        setIsVisible={setOpenDeliveryModal}
        showSuccessMessage={true}
      />

      <PrintingModal isVisible={isPrinting} />
    </Modal>
  );
};

export default CashPayment;
