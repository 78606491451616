import { withApollo } from '@apollo/react-hoc';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import Icon from 'components/Icon';
import { useMe } from 'hooks/useMe';
import Images from 'images';
import { getMenu } from 'layouts/AdminLayout/component/getMenusList';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import storage from 'shared/storage';
import GetStripeLogin from '../AdminLayout/gql/get-login';
import { TicketHossLogo, UserName } from '../AdminLayout/StyledAdminLayout';
import SubMenu from './component/SubMenu';
import {
  GlobalStyle,
  BackgroundContainer,
  NavMenu,
  LogoWrapper,
  Logo,
  NavItem,
  TopBar,
  NavMenuBackground,
  Wrapper,
  NavItemExternal,
  AccountsWrapper,
  NavItemButton,
} from './StyledAdminMobileLayout';

const AdminLayout = ({
  children,
  getStripeLogin,
  backgroundColor,
  history,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { data: user } = useMe();

  const { dispatch } = useContext(AppContext);
  const [account, setAccount] = useState();
  const [capabilities, setCapabilities] = useState();

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const findCapabilities = (accountId) => {
    // eslint-disable-next-line no-unused-expressions
    user?.me?.capabilities?.forEach((capablity) => {
      if (capablity.account_id === accountId) {
        setCapabilities(capablity);
        dispatch({
          type: 'SET_CAPABILITIIES',
          capabilities: capablity,
        });
      }
    });
  };

  const setSelectedAccount = (value) => {
    setAccount(value);
    setAccountId(value.value);
    setAccountName(value.label);
    setAccountScanners(value.janam_scanners);
    setAccountData(value.data);
    findCapabilities(value.value);
  };

  const setAccountId = (accountId) =>
    dispatch({
      type: 'SET_ACCOUNT_ID',
      accountId: accountId,
    });

  const setAccountName = (accountName) =>
    dispatch({
      type: 'SET_ACCOUNT_NAME',
      accountId: accountName,
    });

  const setAccountScanners = (janamScanners) =>
    dispatch({
      type: 'SET_ACCOUNT_SCANNERS',
      janamScanners: janamScanners,
    });

  const setAccountData = (account) =>
    dispatch({
      type: 'SET_ACCOUNT_DATA',
      account: account,
    });

  useEffect(() => {
    if (user && user.me) {
      //save the me value on the context
      dispatch({
        type: 'SET_ME',
        user: user?.me,
      });

      if (user.me?.role !== 'admin') {
        if (Array.isArray(user?.me?.accounts)) {
          if (user.me.accounts && user.me.accounts.length > 0) {
            const selectedAccount = storage.get('selected_account');

            if (selectedAccount) {
              setSelectedAccount(selectedAccount);
            } else {
              setAccount({
                value: user?.me?.accounts[0].id,
                label: user?.me?.accounts[0].business_dba,
                janam_scanners: user?.me?.accounts[0].janam_scanners,
                data: user?.me?.accounts[0],
              });
              setAccountId(user?.me?.accounts[0].id);
              setAccountName(user?.me?.accounts[0].business_dba);
              setAccountScanners(user?.me?.accounts[0].janam_scanners);
              setAccountData(user?.me?.accounts[0]);

              findCapabilities(user?.me?.accounts[0].id);
            }
          } else {
            setShowPermissionModal(true);
          }
        }
        if (Array.isArray(user?.me?.promoters)) {
          dispatch({
            type: 'SET_PROMOTERS',
            promoters: user?.me?.promoters,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  let menu = [];

  menu = getMenu(user?.me?.role, capabilities);

  return !user ? null : (
    <Fragment>
      <BackgroundContainer isVisible={isVisible}>
        <NavMenuBackground isVisible={isVisible}>
          <NavMenu isVisible={isVisible}>
            <Wrapper>
              {user?.me?.accounts?.length > 1 && user?.me?.role !== 'admin' && (
                <AccountsWrapper>
                  <AutoSuggest
                    name="account_id"
                    placeholder="Select Account"
                    value={account}
                    onChange={(value) => {
                      setSelectedAccount(value);

                      setIsVisible(!isVisible);

                      // Store the selected account in local storage
                      storage.set('selected_account', value);
                    }}
                    closeMenuOnSelect
                    options={user?.me?.accounts?.map((item) => ({
                      value: item.id,
                      label: item.business_dba,
                      janam_scanners: item.janam_scanners,
                      data: item,
                    }))}
                    customStyles={{
                      control: (provided) => ({
                        ...provided,
                        height: 49,
                        width: 200,
                      }),
                    }}
                  />
                </AccountsWrapper>
              )}

              {menu.map((item) =>
                !item.external ? (
                  item.shown === true ? (
                    item.submenu ? (
                      <SubMenu
                        item={item}
                        hideMenu={() => setIsVisible(!isVisible)}
                        key={item.id}
                      />
                    ) : (
                      <NavItem
                        exact
                        to={item.link}
                        onClick={() => setIsVisible(!isVisible)}
                        key={item.id}
                      >
                        <div style={{ marginRight: 15, width: 20 }}>
                          <Icon icon={item.icon} size={20} color="white" />
                        </div>{' '}
                        {item.name}
                      </NavItem>
                    )
                  ) : item.shown === false ? (
                    <NavItemButton
                      exact
                      onClick={() => {
                        setIsVisible(!isVisible);
                        setShowPermissionModal(true);
                      }}
                      key={item.id}
                    >
                      <div style={{ marginRight: 15, width: 20 }}>
                        <Icon icon={item.icon} size={20} color="white" />
                      </div>{' '}
                      {item.name}
                    </NavItemButton>
                  ) : null
                ) : (
                  <NavItemExternal
                    target="_blank"
                    href={item.external}
                    key={item.key}
                  >
                    <div style={{ marginRight: 15, width: 20 }}>
                      <Icon icon={item.icon} size={20} color="white" />
                    </div>{' '}
                    {item.name}
                  </NavItemExternal>
                )
              )}

              {user?.me?.role !== 'admin' ? (
                capabilities?.stripe ? (
                  <NavItemExternal
                    style={{ fontWeight: 600 }}
                    onClick={async () => {
                      const result = await getStripeLogin();
                      if (result && result.data && result.data.getStripeLogin) {
                        window.location.assign(result.data.getStripeLogin);
                      } else {
                        history.push({
                          pathname: '/stripe',
                          search: '',
                          state: {
                            email: storage.get('user').email,
                            id: storage.get('user').id,
                          },
                        });
                      }
                    }}
                    target="_blank"
                    key={9}
                  >
                    <div style={{ marginRight: 15, width: 20 }}>
                      <Icon icon="Stripe-Icon" size={20} color="white" />
                    </div>{' '}
                    Stripe
                  </NavItemExternal>
                ) : (
                  <NavItemButton
                    exact
                    onClick={() => {
                      setIsVisible(!isVisible);
                      setShowPermissionModal(true);
                    }}
                    key={9}
                  >
                    <div style={{ marginRight: 15, width: 20 }}>
                      <Icon icon="Stripe-Icon" size={20} color="white" />
                    </div>{' '}
                    Stripe
                  </NavItemButton>
                )
              ) : null}

              <NavItem
                exact
                onClick={() => setIsVisible(!isVisible)}
                to={
                  'admin' === user?.me?.role
                    ? '/admin/settings'
                    : 'track' === user?.me?.role
                    ? '/admin-track/settings/0'
                    : '/admin-employee/settings/0'
                }
                key="001"
              >
                <UserName>
                  {/* Text with the initials of the names */}
                  {`${user?.me?.first_name[0]}${user?.me?.last_name[0]}`}
                </UserName>
                {`${user?.me?.first_name} ${user?.me?.last_name}`}
              </NavItem>
            </Wrapper>
          </NavMenu>
        </NavMenuBackground>

        <TopBar>
          <div
            style={{
              width: 20,
              height: 20,
              position: 'absolute',
              margin: 'auto',
              top: 0,
              left: 20,
              bottom: 0,
              cursor: 'pointer',
            }}
            onClick={() => setIsVisible(!isVisible)}
            onKeyUp={() => setIsVisible(!isVisible)}
            role="button"
            tabIndex={0}
          >
            <Icon icon="Menu-Expand" size={24} color="white" />
          </div>{' '}
          {'pitpay' === process.env.REACT_APP_PLATFORM ? (
            <LogoWrapper>
              <Logo src={Images.logoHorizontalWhite} />
            </LogoWrapper>
          ) : 'tickethoss' === process.env.REACT_APP_PLATFORM ? (
            <LogoWrapper>
              <TicketHossLogo
                src={Images.logoTickethossHorizontal}
                style={{ width: 100 }}
              />
            </LogoWrapper>
          ) : (
            <LogoWrapper>
              <Logo src={Images.logoKart} />
            </LogoWrapper>
          )}
        </TopBar>

        <div
          style={{
            width: '100%',
          }}
        >
          {children}
          <PermissionModal
            isVisible={showPermissionModal}
            hideModal={() => setShowPermissionModal(false)}
            link={
              'track' === user?.me?.role
                ? '/admin-track/settings/1'
                : '/admin-employee/settings/1'
            }
            history={history}
          />
        </div>
      </BackgroundContainer>
      <GlobalStyle backgroundColor={backgroundColor} />
    </Fragment>
  );
};

export default compose(withRouter, withApollo, GetStripeLogin)(AdminLayout);
