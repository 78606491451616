import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import Icon from 'components/Icon';
import Spacer from 'components/Spacer';

const MainCard = styled.div`
  background: ${(props) => props.theme.colors.primary};
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  max-width: 250px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;

  // Hover effect
  transition: 0.3s;
  &:hover {
    transform: scale(1.05);
  }

  // Responsive design
  @media (max-width: 768px) {
    min-height: 60px;
    flex-direction: row;
    padding: 8px;
    gap: 6px;
    justify-content: start;
    padding-left: 20px;
    min-width: 95%;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: 'Barlow Condensed';
`;

const Detail = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const MenuCard = ({ title, detail, url, onClick, component, icon }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(url);
  };

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return component ? (
    <div>
      {React.cloneElement(component, {
        button: (
          <MainCard>
            <Icon icon={icon ? icon : 'scan-icon'} size={40} color="#0b0d38" />
            <Spacer height={20} />

            <Title>{title}</Title>
            <Spacer height={20} />

            {!isMobile && <Detail>{detail}</Detail>}
          </MainCard>
        ),
      })}
    </div>
  ) : (
    <MainCard onClick={onClick ? onClick : handleClick}>
      <Icon icon={icon ? icon : 'scan-icon'} size={40} color="#0b0d38" />
      <Spacer height={20} />

      <Title>{title}</Title>
      <Spacer height={20} />

      {!isMobile && <Detail>{detail}</Detail>}
    </MainCard>
  );
};
