export const getMenu = (role, capabilities) => {
  let menu;

  switch (role) {
    case 'admin':
      menu = [
        {
          id: 1,
          link: '/admin/events',
          icon: 'Events',
          name: 'Events',
          shown: true,
        },
        {
          id: 2,
          link: '/admin/users',
          icon: 'Customers',
          name: 'Users',
          shown: true,
          submenu: 'tickethoss' !== process.env.REACT_APP_PLATFORM && [
            {
              title: 'All Users',
              link: '/admin/users',
            },
            {
              title: 'Temporary',
              link: '/admin/temporaryUsers',
            },
            {
              title: 'Approved',
              link: '/admin/approvedUsers',
            },
          ],
        },
        {
          id: 3,
          link: '/admin/accounts',
          icon: 'Accounts',
          name: 'Accounts',
          shown: true,
        },
        {
          id: 4,
          link: '/admin/tracks',
          icon: 'Tracks',
          name: 'Tracks',
          shown: true,
        },
        {
          id: 5,
          link: '/admin/series',
          icon: 'Series',
          name: 'Series',
          shown: true,
        },
        {
          id: 6,
          link: '/admin/notifications',
          icon: 'Message-Center',
          name: 'Message Ctr',
          shown: true,
        },
        {
          id: 7,
          link: '/admin/annualTickets',
          icon: 'Season-Tickets',
          name: 'Annual / Season',
          shown: true,
          submenu: [
            {
              title: 'Categories',
              link: '/admin/annualcategories',
            },
            {
              title: 'Tickets',
              link: '/admin/annualTickets',
            },

            {
              title: 'Members',
              link: '/admin/annualMembersAccount',
            },
            {
              title: 'Annuals',
              link: '/admin/annualsAccount',
            },
          ],
        },
        'tickethoss' !== process.env.REACT_APP_PLATFORM
          ? {
              id: 8,
              link: '/admin/registrations',
              icon: 'Registrations',
              name: 'Registrations',
              shown: true,
            }
          : { id: 8 },

        {
          id: 9,
          link: '/admin/transactions',
          icon: 'Transactions',
          name: 'Transactions',
          shown: true,
          submenu: [
            {
              title: 'Events',
              link: '/admin/transactions',
            },
            {
              title: 'Annual / Season',
              link: '/admin/annualPurchases',
            },
          ],
        },
        {
          id: 10,
          link: '/admin/credits',
          icon: 'Credits',
          name: 'Credits',
          shown: true,
          submenu: [
            {
              title: 'Open Credits',
              link: '/admin/openCredits',
            },
            {
              title: 'Redeemed Credits',
              link: '/admin/redeemedCredits',
            },
            {
              title: 'Reports',
              link: '/admin/financials',
            },
          ],
        },
        {
          id: 11,
          link: '/admin/promos',
          icon: 'Discounts',
          name: 'Discounts',
          shown: true,
        },
        {
          id: 12,
          link: '/admin/categories',
          icon: 'Dashboard',
          name: 'Dashboard',
          shown: true,
          submenu: [
            {
              title: 'Ticket Categories',
              link: '/admin/categories',
            },
            {
              title: 'Info Tab Categories',
              link: '/admin/infos',
            },
            {
              title: 'Track Types',
              link: '/admin/track-types',
            },
            {
              title: 'Series Types',
              link: '/admin/series-types',
            },
            {
              title: 'Videos',
              link: '/admin/videos',
            },
            {
              title: 'Reports',
              link: '/admin/reports',
            },
            'tickethoss' !== process.env.REACT_APP_PLATFORM && {
              title: 'Waivers',
              link: '/admin/waivers',
            },
          ],
        },
      ];
      break;
    case 'track' || 'employee':
      menu = [
        {
          id: 1,
          link: `/admin-${role}/home`,
          icon: 'Home',
          name: 'Home',
          shown: true,
        },
        {
          id: 2,
          link: `/admin-${role}/events`,
          icon: 'Events',
          name: 'Events',
          shown: capabilities?.events ?? false,
        },
        {
          id: 3,
          link: `/admin-${role}/weather`,
          icon: 'Weather',
          name: 'Weather',
          shown: true,
        },
        {
          id: 4,
          link: `/admin-${role}/notifications`,
          icon: 'Message-Center',
          name: 'Message Ctr',
          shown: capabilities?.messages ?? false,
        },
        {
          id: 5,
          link: `/admin-${role}/guest-lists`,
          icon: 'Guest-List',
          name: 'Guest List',
          shown: capabilities?.guests ?? false,
        },
        {
          id: 6,
          link: `/admin-${role}/annualMembers`,
          icon:
            'tickethoss' !== process.env.REACT_APP_PLATFORM
              ? 'Members'
              : 'Season-Tickets',
          name:
            'tickethoss' !== process.env.REACT_APP_PLATFORM
              ? 'Annuals'
              : 'Season Tickets',
          shown: capabilities?.members ?? true,
          submenu: [
            'tickethoss' !== process.env.REACT_APP_PLATFORM && {
              title: 'Members',
              link: `/admin-${role}/annualMembers`,
            },
            {
              title:
                'tickethoss' !== process.env.REACT_APP_PLATFORM
                  ? 'Full Season'
                  : 'Ticket Holders',
              link: `/admin-${role}/annualUsers`,
            },
          ],
        },
        'tickethoss' !== process.env.REACT_APP_PLATFORM
          ? {
              id: 7,
              link: `/admin-${role}/registrations`,
              icon: 'Registrations',
              name: 'Registrations',
              shown: capabilities?.registrations ?? false,
            }
          : { id: 7 },
        {
          id: 8,
          link: `/admin-${role}/transactions`,
          icon: 'Transactions',
          name: 'Transactions',
          shown: capabilities?.transactions ?? false,
          submenu: [
            {
              title: 'Events',
              link: `/admin-${role}/transactions`,
            },
            {
              title: 'Full Season',
              link: `/admin-${role}/annualPurchases`,
            },
          ],
        },
        {
          id: 9,
          link: `/admin-${role}/credits`,
          icon: 'Credits',
          name: 'Credits',
          shown: capabilities?.credits ?? false,
          submenu: [
            {
              title: 'Open Credits',
              link: `/admin-${role}/openCredits`,
            },
            {
              title: 'Redeemed Credits',
              link: `/admin-${role}/redeemedCredits`,
            },
            {
              title: 'Reports',
              link: `/admin-${role}/financials`,
            },
          ],
        },
        {
          id: 10,
          link: `/admin-${role}/promos`,
          icon: 'Discounts',
          name: 'Promo Codes',
          shown: capabilities?.promos ?? false,
        },
        {
          id: 11,
          link: `/admin-${role}/users`,
          icon: 'Customers',
          name: 'Customers',
          shown: capabilities?.customers ?? false,
        },
        {
          id: 12,
          link: `/admin-${role}/employees`,
          icon: 'Staff',
          name: 'Staff',
          shown: capabilities?.staff ?? false,
        },
        {
          id: 13,
          link: null,
          icon: 'Help',
          name: 'Help Resources',
          shown: true,
          external:
            process.env.REACT_APP_PLATFORM === 'tickethoss'
              ? 'https://promoters.tickethoss.com/'
              : 'https://promoters.pitpay.com/',
        },
        {
          id: 14,
          link: `/admin-${role}/contact`,
          icon: 'Contact',
          name: 'Contact Pit Pay',
          shown: true,
        },
      ];
      break;
    default:
      menu = [];
      break;
  }

  return menu;
};
