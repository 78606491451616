import moment from 'moment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { EventHeaderMobile } from 'components/Events';
// import { PrintParticipants } from 'components/Participants/PrintSpectators';
import { SearchableListContainer } from 'components/SearchableListContainer';
// import Export from 'pages/app/Tickets/export';
// import { TicketReportPrint } from 'pages/app/Transactions/TicketReportPrint/TicketReportPrint';
import { TransactionsPrintList } from 'pages/app/Transactions/TransactionsPrintList';
import { EventHeader } from '../EventsMenu/components/EventHeader';
import { MenuCard } from '../EventsMenu/components/MenuCard';

const Container = styled.div`
  // Grid display with a maximum of 4 columns
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-content: center;

  // Responsive design
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    margin: 0 10px 10px 10px;
    padding: 20px;
  }
`;

export const EventsReport = () => {
  // Get id from the URL
  const { id } = useParams();

  const history = useHistory();

  const location = useLocation();
  const { event } = location.state || {};

  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : pathname.split('/').includes('admin-track')
    ? 'track'
    : 'admin';

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const MenuList = [
    {
      title:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Participants Report'
          : 'Ticket Holder Report',
      detail:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Report containing a list of all Participants. Report contains purchaser, ticket #, etc.'
          : 'Report containing a list of all spectators. Report contains purchaser, ticket #, etc.',
      onClick: () =>
        userType === 'admin'
          ? history.push(
              `/${userType}/events/reports/spectatorsList/${id}?date=${event.start_date}`,
              {
                event: event,
              }
            )
          : history.push(
              `/admin-${userType}/events/reports/spectatorsList/${id}?date=${event.start_date}`,
              {
                event: event,
              }
            ),
      icon: 'Reports',
    },
    // {
    //   title: 'Specators List CSV',
    //   detail:
    //     'CSV report containing a list of all spectators. Report contains purchaser, ticket #, etc.',
    //   component: <Export event={event} date={event?.start_date} />,
    // },
    {
      title: 'Transaction Report',
      detail:
        'This is the Payout report which containing all transactions for this event.',
      component: (
        <TransactionsPrintList
          date={
            event?.isMultiDay
              ? `${event?.fullMonth} ${event?.listDates} - ${moment(
                  event?.end_date
                ).format('YYYY')} `
              : event?.fullDate
          }
          eventId={event?.id}
        />
      ),
      icon: 'Reports',
    },
    {
      title:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Pass Breakdown Report'
          : 'Ticket Breakdown Report',
      detail:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Report breaks down how many of each pass were sold.'
          : 'Report breaks down how many of each ticket were sold.',
      onClick: () =>
        userType === 'admin'
          ? history.push(`/${userType}/events/reports/ticketBreakdown/${id}`, {
              event: event,
            })
          : history.push(
              `/admin-${userType}/events/reports/ticketBreakdown/${id}`,
              {
                event: event,
              }
            ),
      icon: 'Reports',
    },
    // {
    //   title: 'Register Report',
    //   detail:
    //     'Report used to balance the box office register at the end of the day.',
    //   url:
    //     userType === 'admin'
    //       ? `/${userType}/transactions/event/${id}`
    //       : `/admin-${userType}/transactions/event/${id}`,
    //   icon: 'Reports',
    // },
    // {
    //   title: 'Event Transaction Audit',
    //   detail:
    //     'Breaks down which ticket sold and method of payment used. Cash, Card, Credit, etc.',
    //   url: '/settings',
    //   icon: 'Reports',
    // },
    // {
    //   title: 'Point Of Sale Cash Report',
    //   detail: 'Breaks down the total amount of cash sales for this event.',
    //   url:
    //     userType === 'admin'
    //       ? `/${userType}/events/reports/cash-report/${id}`
    //       : `/admin-${userType}/events/reports/cash-report/${id}`,
    //   icon: 'Reports',
    // },
  ];

  const MenuContainer = (
    <Container>
      {MenuList.map((menu, index) => (
        <MenuCard key={index} {...menu} />
      ))}
    </Container>
  );

  return (
    <>
      {isMobile ? (
        <>
          <EventHeaderMobile queryDate={event?.date} noDropdown />
          {MenuContainer}
        </>
      ) : (
        <SearchableListContainer
          header={event ? <EventHeader event={event} /> : undefined}
          title="Reports"
          noSearch
        >
          {MenuContainer}
        </SearchableListContainer>
      )}
    </>
  );
};
