import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const TICKET_BREAKDOWN_REPORT = gql`
  mutation TicketBreakdownReportMutation($eventId: Int!) {
    getTicketSalesReport(event_id: $eventId) {
      event {
        name
        month
        day
        date
        isMultiDay
        start_date
        end_date
        track {
          name
        }
      }
      total
      discounts
      credits
      results {
        name
        price
        qty
      }
    }
  }
`;

export function useGetTicketBreakdownReport() {
  const [ticketBreakdownMutation] = useMutation(TICKET_BREAKDOWN_REPORT);
  const result = useCallback(
    (eventId) => {
      return ticketBreakdownMutation({
        variables: {
          eventId: Number(eventId),
        },
      });
    },
    [ticketBreakdownMutation]
  );
  return result;
}
