import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import Spacer from 'components/Spacer';
const { Modal } = require('components/Modal');

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const InfoText = styled.span`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #00001f;
  font-family: 'Barlow Condensed';
  text-align: start;
  max-width: 400px;
`;

export const ConfirmMoveSeatsModal = ({
  isVisible,
  setIsVisible,
  selectedSeats,
  newSelectedSeats,
  onMoveSeatsComplete,
}) => {
  const theme = useTheme();

  const onConfirmMoveSeats = () => {
    onMoveSeatsComplete();
    setIsVisible(false);
  };

  return (
    <Modal
      isVisible={isVisible}
      hideModal={() => setIsVisible(false)}
      title={'Review Seat Move'}
      maxWidth={600}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          padding: 35,
          justifyContent: 'center',
        }}
      >
        <InfoText style={{ maxWidth: '100%' }}>
          Please review the following selections. If everything looks good,
          click submit to finilize this change.
        </InfoText>

        <Spacer size={15} />
        {selectedSeats?.length > 0 &&
          newSelectedSeats?.length > 0 &&
          selectedSeats.map((seat, index) => (
            <InfoRow>
              <InfoText>
                {seat.name}, Row {seat.row}, Seat {seat.seat}
              </InfoText>

              <div
                style={{
                  paddingInline: 10,
                  transform: 'rotate(180deg)',
                }}
              >
                <Icon
                  icon={'left-arrow'}
                  size={22}
                  color={theme.colors.primary}
                />
              </div>

              <InfoText>
                {newSelectedSeats[index]?.category?.label}, Row{' '}
                {newSelectedSeats[index]?.labels?.parent}, Seat{' '}
                {newSelectedSeats[index]?.labels?.own}
              </InfoText>
            </InfoRow>
          ))}

        <Spacer size={15} />

        {/* Submit and cancel buttons */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 15,
          }}
        >
          <Button onClick={() => setIsVisible(false)} outlined>
            Cancel
          </Button>
          <Button onClick={onConfirmMoveSeats}>Submit</Button>
        </div>
      </div>
    </Modal>
  );
};
