import React from 'react';
import { useTheme } from 'styled-components';
import { DateCard } from 'components/DateCard';
import Text from 'components/Text';

export const EventHeader = ({ event, margin = 40 }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin,
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DateCard item={event} margin={'margin: 0 10px 0 0'} horizontal />
        <Text type="heading" fontSize={32} color={theme.colors.secondary}>
          {event.name}
        </Text>
      </div>
    </div>
  );
};
