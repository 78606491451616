/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from 'components/Button';
import Container from 'components/Container';
import { CheckInModal } from 'components/Modal';
import {
  useCheckInUser,
  useUncheckInUser,
  useGetStoredPurchaseIds,
} from 'components/Participants/gql';
import { useGetSpectatorPurchase } from 'components/Participants/gql/useGetSpectatorPurchase';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { TransactionModal } from 'components/Transactions';
import { useOfflineCheck } from 'hooks/useOfflineCheck';
import useTheme from 'hooks/useTheme';
import { NameLink } from 'pages/app/Tickets';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import { AssosiatedTickets } from './AssosiatedTickets';
import { TicketDetailModal } from './TicketDetailModal';

const OpenModal = styled.button`
  color: #fa4616;
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-family: 'Roboto';
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const Content = styled.section`
  padding: 20px;
`;

const Heading = styled.div`
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
`;

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AllPassesButtons = styled.div`
  display: flex;
  flex-direction: column;
  & > :first-child {
    margin-bottom: 12px;
  }

  @media (min-width: 859px) {
    flex-direction: row;
    & > :first-child {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }
`;
export const TicketDetails = ({ employeeAdmin, match, location }) => {
  const theme = useTheme();
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [storedCheckins, setStoredCheckins] = useState([]);
  const [storedUnChecks, setStoredUnChecks] = useState([]);
  const getStoredPurchaseIds = useGetStoredPurchaseIds();
  const { date } = qs.parse(location.search.substring(1));
  const { res } = useGetSpectatorPurchase({ date });
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [ticket, setTicket] = useState(null);
  const [shouldDisplayCheckInModal, setShouldDisplayCheckInModal] =
    useState(false);

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const {
    state: { capabilities },
  } = useContext(AppContext);

  const [unCheck, setUncheck] = useState(false);
  const [purchaseItem, setPurchaseItem] = useState(false);
  const checkInUser = useCheckInUser();
  const uncheckInUser = useUncheckInUser();

  async function onConfirmCheckIn() {
    if (unCheck) {
      setStoredUnChecks((prev) => [...new Set(prev.concat(purchaseItem.id))]);
    } else {
      setStoredCheckins((prev) => [...new Set(prev.concat(purchaseItem.id))]);
    }

    const response = unCheck
      ? await uncheckInUser([purchaseItem.id])
      : await checkInUser([purchaseItem.id]);

    if (response && !response.errors) {
      const newTicket = unCheck
        ? response.data?.uncheckInUserV2[0]
        : response.data?.checkInUserV2[0];

      const newTickets = ticket.tickets.map((ticket) => {
        if (ticket.id === newTicket?.id) {
          const ass = { ...ticket };
          ass.is_checked = newTicket.is_checked;
          return ass;
        } else return ticket;
      });
      const tick = { ...ticket };
      tick.tickets = newTickets;
      setTicket(tick);
    }

    return response;
  }

  const [allTicketsStoredToCheckIn, setallTicketsStoredToCheckIn] = useState(0);
  const [allTicketsStoredToUnCheck, setallTicketsStoredToUnCheck] = useState(0);
  const [isAllCheckedIn, setisAllCheckedIn] = useState(false);
  const [isAllUnCheckedIn, setisAllUnCheckedIn] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [currentTicket, setCurrentTicket] = useState(null);

  useEffect(() => {
    if (res && res.getSpectatorPurchase) {
      setTicket(res?.getSpectatorPurchase);
      setallTicketsStoredToCheckIn(
        res?.getSpectatorPurchase.tickets.reduce(
          (allStored, tick) =>
            !allStored ? allStored : storedCheckins.includes(tick.id),
          true
        )
      );
      setallTicketsStoredToUnCheck(
        res?.getSpectatorPurchase.tickets.reduce(
          (allStored, tick) =>
            !allStored ? allStored : storedUnChecks.includes(tick.id),
          true
        )
      );
      setisAllCheckedIn(
        res?.getSpectatorPurchase.tickets.reduce(
          (allChecked, tick) => (!allChecked ? allChecked : !!tick?.is_checked),
          true
        )
      );
      setisAllUnCheckedIn(
        res?.getSpectatorPurchase.tickets.reduce(
          (allUnChecked, tick) =>
            !allUnChecked ? allUnChecked : !tick?.is_checked,
          true
        )
      );
    }
  }, [res]);

  const isOffline = useOfflineCheck();

  useEffect(() => {
    if (isOffline) {
      const storedPurchaseIdsToCheckIn = getStoredPurchaseIds('check');
      const storedPurchaseIdsToUnCheck = getStoredPurchaseIds('uncheck');
      setStoredCheckins(storedPurchaseIdsToCheckIn);
      setStoredUnChecks(storedPurchaseIdsToUnCheck);
    }
  }, [isOffline, getStoredPurchaseIds]);
  const disableCheckInButton = isOffline
    ? allTicketsStoredToCheckIn || isAllCheckedIn
    : isAllCheckedIn;
  const disableUnCheckButton = isOffline
    ? allTicketsStoredToUnCheck || isAllUnCheckedIn
    : isAllUnCheckedIn;
  useEffect(() => {
    if (location.query) {
      setSelectedPeople([...location.query]);
    }
  }, [location.query]);

  if (!ticket) return false;

  async function checkInAllPasses() {
    function onSuccess() {
      toast.success('Succesfully checked in all passes');
      const listPage = pathname
        .split('/')
        .slice(0, -1)
        .join('/')
        .concat(search);
      history.push(listPage);
    }

    const onError = () => toast.error("Couldn't complete check in");

    const purchase_ids = ticket.tickets.map((ticket) => ticket.id);
    setStoredCheckins(purchase_ids);
    await checkInUser(purchase_ids, 'manual', onSuccess, onError);
  }

  async function uncheckAllPasses() {
    function onSuccess() {
      toast.success('Unchecked all passes');
      const listPage = pathname
        .split('/')
        .slice(0, -1)
        .join('/')
        .concat(search);
      history.push(listPage);
    }

    const onError = () => toast.error("Couldn't uncheck all passes");

    const purchase_ids = ticket.tickets.map((ticket) => ticket.id);
    setStoredUnChecks(purchase_ids);
    await uncheckInUser(purchase_ids, 'manual', onSuccess, onError);
  }
  return (
    <Container>
      <Content>
        <Heading>
          <Text type="heading">Order Details</Text>
        </Heading>
        <Spacer size={15} />

        <DataRow>
          <Text
            type="bold"
            color={theme.colors.text.gray}
            inlineStyle={{ width: '35%', marginRight: 8 }}
          >
            Purchaser:
          </Text>
          <Text
            type="bold"
            fontWeight="600"
            inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
          >
            {' '}
            {ticket.transaction && ticket.transaction.user ? (
              <NameLink
                highlight={'orange'}
                to={{
                  pathname: `/admin/users/transactions/${ticket.transaction.user.id}`,
                }}
              >
                {ticket.purchaser ? ticket.purchaser : ''}
              </NameLink>
            ) : (
              <Text inlineStyle={{ fontSize: 16, fontFamily: 'Roboto' }}>
                {ticket.transaction.id}
              </Text>
            )}
          </Text>
        </DataRow>

        <Spacer size={15} />
        <DataRow>
          <Text
            type="bold"
            color={theme.colors.text.gray}
            inlineStyle={{ width: '35%', marginRight: 8 }}
          >
            Transaction:
          </Text>
          <Text
            type="bold"
            fontWeight="600"
            inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
          >
            <OpenModal
              onClick={() => {
                setCurrentTransaction(ticket.transaction);
              }}
            >
              View
            </OpenModal>
          </Text>
        </DataRow>

        <Spacer size={15} />
        {ticket.refund ? (
          <DataRow>
            <Text type="bold" color="#707273" inlineStyle={{ width: '50%' }}>
              Refund:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{
                width: '50%',
                whiteSpace: 'normal',
                marginLeft: 10,
              }}
            >
              {ticket.refund}
            </Text>
          </DataRow>
        ) : null}

        <>
          <Heading>
            <Text type="heading">Tickets</Text>
          </Heading>
          <AssosiatedTickets
            currentUser={ticket}
            setCurrentTicket={setCurrentTicket}
            onChange={(item) => {
              setPurchaseItem(item);
              setUncheck(item.is_checked);
              if (!capabilities || capabilities?.check_tickets)
                setShouldDisplayCheckInModal(true);
              else setShowPermissionModal(true);
            }}
          />
        </>

        <Spacer size={15} />
        {!employeeAdmin ? (
          <AllPassesButtons>
            <Button
              fontSize={22}
              onClick={
                !capabilities || capabilities?.check_tickets
                  ? checkInAllPasses
                  : () => setShowPermissionModal(true)
              }
              disabled={disableCheckInButton}
            >
              {process.env.REACT_APP_PLATFORM !== 'tickethoss'
                ? 'Check In All Passes'
                : 'Check In All Tickets'}
            </Button>
            <Button
              fontSize={22}
              onClick={
                !capabilities || capabilities?.check_tickets
                  ? uncheckAllPasses
                  : () => setShowPermissionModal(true)
              }
              disabled={disableUnCheckButton}
            >
              {process.env.REACT_APP_PLATFORM !== 'tickethoss'
                ? 'Uncheck All Passes'
                : 'Uncheck All Tickets'}
            </Button>
          </AllPassesButtons>
        ) : null}
      </Content>
      {currentTransaction ? (
        <TransactionModal
          admin={location.pathname.includes('/admin/')}
          match={match}
          currentTransaction={currentTransaction}
          close={() => {
            setCurrentTransaction(null);
          }}
        />
      ) : null}

      {currentTicket && (
        <TicketDetailModal
          close={() => {
            setCurrentTicket(null);
          }}
          ticketId={currentTicket}
          date={date}
          match={match}
          location={location}
        />
      )}

      <CheckInModal
        itemType={
          process.env.REACT_APP_PLATFORM !== 'tickethoss' ? 'Pass' : 'Ticket'
        }
        isVisible={!!shouldDisplayCheckInModal}
        onConfirm={onConfirmCheckIn}
        setIsVisible={setShouldDisplayCheckInModal}
        unCheck={unCheck}
      />
      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={history}
      />
    </Container>
  );
};

const TransactionInformationRow = (props) => {
  const { label, data, noSpacer } = props;
  return (
    <>
      <DataRow>
        <Text type="bold" color="#707273" inlineStyle={{ width: '50%' }}>
          {label}:
        </Text>
        <Text
          type="bold"
          fontWeight="600"
          inlineStyle={{
            width: '50%',
            whiteSpace: 'normal',
            marginLeft: 10,
            textTransform: 'capitalize',
          }}
        >
          {data}
        </Text>
      </DataRow>
      {!noSpacer && <Spacer size={15} />}
    </>
  );
};
TransactionInformationRow.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  noSpacer: PropTypes.bool,
};
