import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import Spacer from 'components/Spacer';
import { AppContext } from 'shared/AppContext';
import { useCart } from 'shared/CartContext';
import CashPayment from './CashPayment';
import { usePosReview } from './gql/queries/usePosReview';
import { PaymentOption } from './PaymentOption';
import { ReaderSelectionModal } from './ReaderSelectionModal';
import { TimerCountdown } from './TimerCountdown';

export const CartList = () => {
  const {
    tickets,
    total,
    setTotal,
    clearTicket,
    // discount,
    setDiscount,
    subtotal,
    setSubtotal,
    setAmount,
    setSeatsModalIsVisible,
    accountId,
    event,
    account: eventAccount,
  } = useCart();

  const {
    state: { account },
  } = useContext(AppContext);

  const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openReaderModal, setOpenReaderModal] = useState(false);

  const OpenConfirmModal = () => {
    // Check if there is a saved reader in the cookie
    if (
      document.cookie
        .split(';')
        .some((item) => item.trim().startsWith(`${accountId}-reader`))
    ) {
      setIsCheckoutVisible(true);
    } else {
      // If there is no reader saved in the cookie, show the reader selection modal
      setOpenReaderModal(true);
    }
  };

  const OpenCashModal = () => {
    setOpenSuccessModal(true);
  };

  const theme = useTheme();

  const params = useParams();
  const EventId = parseInt(params.id);

  const allTickets = Object.values(tickets).reduce((acc, value) => {
    const tickets = value.tickets?.map((ticket) => ({
      id: ticket.id,
      qty: ticket.count,
      ...(ticket.reserved_seat && ticket.count > 0
        ? {
            seats: ticket.seats?.map((seat) => ({
              seat_id: seat.id,
              ...(seat?.type ? { type: seat.type } : null),
            })),
          }
        : null),
    }));

    return [...acc, ...tickets];
  }, []);

  const { data, loading } = usePosReview(EventId, allTickets);

  useEffect(() => {
    if (!loading && data) {
      setTotal(data?.posReview?.subtotal);
      setSubtotal(data?.posReview?.subtotal);
      setDiscount(data?.posReview?.discount);
      setAmount(data?.posReview?.amount);
    }
  }, [data, loading, setTotal, setDiscount, setSubtotal, setAmount]);

  const [timer, setTimer] = useState(600000);
  const seatsio = JSON.parse(sessionStorage.getItem('seatsio'));
  const holdToken = seatsio?.holdToken;

  const storedTimer = localStorage.getItem(`timer - ${holdToken}`);

  useEffect(() => {
    let interval;
    const timer = () => {
      const seatsio = JSON.parse(sessionStorage.getItem('seatsio'));
      const holdToken = seatsio?.holdToken;

      if (!holdToken) return;

      const storedTimer = localStorage.getItem(`timer - ${holdToken}`);

      let expiryTime;

      if (storedTimer) {
        expiryTime = parseInt(storedTimer, 10);

        interval = setInterval(() => {
          const timeLeft = expiryTime - new Date().getTime();
          if (timeLeft <= 0) {
            clearInterval(interval);
            clearTicket();
            setTimer(600000);

            // Remove any old timer keys
            Object.keys(localStorage).forEach((key) => {
              if (key.includes('timer')) localStorage.removeItem(key);
            });
          } else {
            setTimer(timeLeft);
          }
        }, 1000);
      }
    };

    timer();

    return () => clearInterval(interval);
  }, [setTimer, clearTicket]);

  const foundReservedSeat = Object.values(tickets).reduce((acc, value) => {
    const found = value.tickets?.some(
      (ticket) => ticket.reserved_seat && ticket.count > 0
    );

    return acc || found;
  }, false);

  const foundTickets = Object.values(tickets).reduce((acc, value) => {
    const found = value.tickets?.some(
      (ticket) => !ticket.reserved_seat && ticket.count > 0
    );

    return acc || found;
  }, false);

  const availableAccount = account || eventAccount;

  const posSettings =
    event && event?.pos_settings
      ? event?.pos_settings
      : availableAccount && availableAccount?.pos_settings
      ? availableAccount?.pos_settings
      : undefined;

  const cardOption =
    posSettings && posSettings?.payment
      ? posSettings?.payment === 'both' || posSettings?.payment === 'card'
      : true;

  const cashOption =
    posSettings && posSettings?.payment
      ? posSettings?.payment === 'both' || posSettings?.payment === 'cash'
      : true;

  return (
    <MainContainer>
      <CartProductList>
        <CartHeader>
          <CartText>Cart</CartText>
          <CartHeaderButtons>
            {storedTimer && <TimerCountdown timer={timer} />}

            <Button
              buttonStyle={{
                backgroundColor: 'white',
                borderRadius: 8,
                padding: 0,
                height: 51,
              }}
              textColor={'#fa4616'}
              onClick={clearTicket}
              outlined
            >
              <div style={{ gap: 5, display: 'flex' }}>
                Clear
                <Icon
                  icon={'trash'}
                  size={16}
                  color={theme.colors.primary}
                  customStyle={{ marginTop: '-2px' }}
                />
              </div>
            </Button>
          </CartHeaderButtons>
        </CartHeader>
        <CartBody>
          {/* check if there is one ticket with reserved_seat = true to display the section*/}
          {foundReservedSeat && (
            <TicketListContainer>
              <TicketText>Reserved Seat</TicketText>

              {Object.values(tickets).map((value) =>
                value.tickets
                  ?.filter((ticket) => ticket.reserved_seat)
                  ?.map(
                    (ticket) =>
                      ticket.count > 0 &&
                      ticket.seats?.length > 0 &&
                      ticket.seats.map((seat) => (
                        <Ticket
                          ticket={ticket}
                          theme={theme}
                          key={ticket.id}
                          reserved={true}
                          seat={seat}
                          setIsVisible={setSeatsModalIsVisible}
                        />
                      ))
                  )
              )}
            </TicketListContainer>
          )}

          {foundTickets && (
            <TicketListContainer>
              <TicketText>Tickets</TicketText>

              {Object.values(tickets).map((value) =>
                value.tickets
                  ?.filter((ticket) => !ticket.reserved_seat)
                  ?.map(
                    (ticket) =>
                      ticket.count > 0 && (
                        <Ticket ticket={ticket} theme={theme} key={ticket.id} />
                      )
                  )
              )}
            </TicketListContainer>
          )}
        </CartBody>
      </CartProductList>{' '}
      <PriceContainer>
        <LabelValueContainer>
          <SubtotalText>Subtotal</SubtotalText>
          <SubtotalText>{subtotal ?? '$0.00'}</SubtotalText>
        </LabelValueContainer>
        {/* <LabelValueContainer>
          <SubtotalText>Discounts</SubtotalText>
          <SubtotalText
            style={{
              padding: '3px 12px 5px 12px',
              border: '1px solid white',
              borderRadius: 8,
            }}
          >
            {discount ?? '$0.00'}
          </SubtotalText>
        </LabelValueContainer> */}
        <div
          style={{
            height: 1,
            opacity: '20%',
            backgroundColor: 'white',
            margin: '10px 0',
          }}
        />
        <LabelValueContainer>
          <TotalText>Total</TotalText>
          <TotalText style={{ lineHeight: '32px' }}>
            {total ?? '$0.00'}
          </TotalText>
        </LabelValueContainer>

        <Spacer size={10} />

        <LabelValueContainer
          style={{
            gap: 15,
            justifyContent: cashOption && cardOption ? 'space-between' : 'end',
          }}
        >
          {cardOption && (
            <Button
              buttonStyle={{
                backgroundColor: 'white',
                borderRadius: 8,
              }}
              textColor={'#fa4616'}
              onClick={OpenConfirmModal}
            >
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '34px',
                }}
              >
                Credit / Debit
              </span>
            </Button>
          )}

          {cashOption && (
            <Button
              buttonStyle={{
                backgroundColor: 'white',
                borderRadius: 8,
              }}
              textColor={'#fa4616'}
              onClick={OpenCashModal}
            >
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '34px',
                }}
              >
                Cash
              </span>
            </Button>
          )}
        </LabelValueContainer>
      </PriceContainer>
      <PaymentOption
        isVisible={isCheckoutVisible}
        setIsVisible={setIsCheckoutVisible}
      />
      <CashPayment
        isVisible={openSuccessModal}
        setIsVisible={setOpenSuccessModal}
      />
      <ReaderSelectionModal
        isVisible={openReaderModal}
        setIsVisible={setOpenReaderModal}
        setIsCheckoutVisible={setIsCheckoutVisible}
      />
    </MainContainer>
  );
};

const Ticket = ({ ticket, theme, reserved, seat, setIsVisible }) => {
  const { removeTicket } = useCart();

  const onRemoveTicket = () => {
    ticket.reserved_seat ? setIsVisible(true) : removeTicket(ticket);
  };

  return (
    <TicketContainer key={ticket?.id}>
      <div
        style={{
          gap: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <NumberText>{reserved ? '1' : ticket?.count}</NumberText>
        {reserved ? (
          <SeatContainer>
            <TicketName>{seat?.labels?.section}</TicketName>
            <SeatText>{`Row: ${seat?.labels?.parent} - Seat: ${seat?.labels?.own}`}</SeatText>
          </SeatContainer>
        ) : (
          <TicketName>{ticket?.name}</TicketName>
        )}
      </div>

      <div style={{ gap: 10, display: 'flex' }}>
        <Price>
          {reserved ? seat?.price : `$${ticket?.price?.toFixed(2)}`}
        </Price>
        <Icon
          icon={'trash'}
          size={18}
          color={theme.colors.primary}
          customStyle={{ cursor: 'pointer' }}
          onClick={onRemoveTicket}
        />
      </div>
    </TicketContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  color: black;
  flex-grow: 1;

  @media (max-width: 984px) {
    max-height: 69vh;
    overflow-y: auto;
  }
`;

const CartProductList = styled.div`
  flex: 1;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  overflow-y: auto;
`;

const PriceContainer = styled.div`
  border-radius: 8px;
  background-color: #fa4616;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: white;
  height: auto;
`;

const CartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #bbbbbb33;
  padding-bottom: 10px;
`;

const CartText = styled.span`
  font-size: 26px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  padding-bottom: 5px;
`;

const TicketText = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  padding-bottom: 10px;
`;

const CartHeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: end;
`;

const CartBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`;

const TicketListContainer = styled.div`
  background: #eaeaea;
  border-radius: 8px;
  padding: 16px;
`;

const TicketContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding-bottom: 10px;
`;

const NumberText = styled.span`
  min-height: 58px;
  min-width: 48px;
  padding: 10px, 16px, 14px, 16px;
  border-radius: 8px;
  gap: 10px;
  border: 2px solid #bbbbbb33;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  //styleName: PP - H4;
  font-family: Barlow Condensed;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
`;
const SeatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: start;
`;

const TicketName = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const SeatText = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #888888;
`;

const Price = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
`;

const LabelValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;

const SubtotalText = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
  //styleName: PP - Body;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const TotalText = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
  //styleName: PP - Body;
  font-family: Barlow Condensed;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;
