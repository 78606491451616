import React, { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTheme } from 'styled-components';
import Icon from 'components/Icon';
import Spacer from 'components/Spacer';
import { NavItemHeader, NavSubItem } from '../StyledAdminLayout';

const SubMenu = ({ item, isMenuOpen, setIsMenuOpen }) => {
  const [active, setActive] = useState(false);
  const theme = useTheme();

  const location = useLocation();
  const pathname = location.pathname;

  // Check if the current path is the same as the submenu link
  const links = item.submenu
    ?.filter((submenu) => !!submenu)
    .map((submenu) => submenu.link);
  const isActive = links.includes(pathname);

  return (
    <>
      <NavItemHeader
        key={item.id}
        onClick={() => {
          if (!isMenuOpen) {
            setIsMenuOpen(true);
            setActive(true);
          } else setActive(!active);
        }}
        isMenuOpen={isMenuOpen}
        isActive={isActive}
        active={active}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <div style={{ width: 20 }}>
            <Icon
              icon={item.icon}
              size={20}
              color="white"
              customStyle={{ justifyContent: 'center', alignContent: 'center' }}
            />
          </div>{' '}
          {!isMenuOpen ? ' ' : item.name}
        </div>
        {isMenuOpen && (
          <div
            style={{
              marginLeft: 10,
              width: 20,
              transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <Icon
              icon={'Down-Arrow'}
              size={17}
              color={isActive && !active ? theme.colors.primary : 'white'}
            />
          </div>
        )}
      </NavItemHeader>
      {isMenuOpen && <Spacer />}
      {isMenuOpen &&
        active &&
        item?.submenu
          ?.filter((submenu) => !!submenu)
          .map((submenu, index) => (
            <NavSubItem
              exact
              to={submenu.link}
              key={`${index}-${submenu.title}`}
              isMenuOpen={isMenuOpen}
            >
              {submenu.title}
            </NavSubItem>
          ))}
    </>
  );
};

export default SubMenu;
